/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'
import { useState } from "react"
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import SEO from '../../components/seo'

interface WineRow {
  allWinesCsvSheet1: {
    edges: {
      node: {
        date: string,
        title: string,
        variety: string,
        country: string,
        region: string,
        instagramUrl: string,
        url: string | null,
      }
    }[]
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 365,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const Wine = ({ data }: { data: WineRow }) => {
  const originalWineNodes = data.allWinesCsvSheet1.edges
  const [wineNodes, setWineNodes] = useState(data.allWinesCsvSheet1.edges)
  const [searchContent, setSearchContent] = useState("")

  const classes = useStyles()

  const searchWine = (content: string) => {
    setWineNodes([...originalWineNodes].filter(node => {
      const title = node.node.title
      if (title == null) return false
      const isIncludeInTitle = title.includes(content)
      const isIncludeInVariety = node.node.variety.includes(content)
      const isIncludeInCountry = node.node.country.includes(content)
      const isIncludeInRegion = node.node.region.includes(content)
      return isIncludeInTitle || isIncludeInVariety || isIncludeInCountry || isIncludeInRegion
    }))
  }

  const changeSearchContent = (e: any) => {
    const content = e.target.value
    setSearchContent(content)
    searchWine(content)
  }

  const search = (e: any) => {
    searchWine(searchContent)
  }

  const wineNodesSorted = wineNodes.sort((node1, node2) => {
    const dateStr1 = Number(node1.node.date.replace('/', '').replace('/', ''))
    const dateStr2 = Number(node2.node.date.replace('/', '').replace('/', ''))
    return dateStr2 - dateStr1
  })
  const wineCards = wineNodesSorted.map((node, i) => {
    if (node.node.title == null) return
    let instagramId = null
    if (node.node.instagramUrl != null) {
      const instagramUrl = node.node.instagramUrl.replace(/\s/g, '')
      const instagramUrlSpritedBySlash = instagramUrl.split('/')
      instagramId = instagramUrlSpritedBySlash[instagramUrlSpritedBySlash.length - 2]
    }
    return (
      <Grid className={classes.root} container justify="center" style={{ padding: 10 }} key={i}>
        <Card>
          <CardHeader
            title={node.node.title}
            subheader={`${node.node.date}に投稿`}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {
                instagramId == null ? null : <iframe src={`//instagram.com/p/${instagramId}/embed/`} width="315" height="325" frameBorder="0" scrolling="no" allowTransparency={true} />
              }
              <div>{node.node.variety}</div>
              <div>{node.node.country} {node.node.region}</div>
              {node.node.url == null ? null : <a href={node.node.url} target="_blank">Importer's site</a>}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  })

  return (
    <Grid container justify="center" spacing={2}>
      <SEO />
      <Grid container justify="flex-end" xs={11} style={{ paddingTop: 5 }}>
        <a href="../recipes">recipes</a>
      </Grid>
      <Grid container justify="center" style={{ padding: 1 }}>
        <h1>wines</h1>
      </Grid>
      <Grid container justify="center" style={{ padding: 10 }}>
        <Paper>
          <InputBase
            className={classes.input}
            placeholder="ワインを検索"
            inputProps={{ 'aria-label': 'ワインを検索' }}
            onChange={changeSearchContent}
          />
          <IconButton className={classes.iconButton} aria-label="search" onClick={search}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
      <br />
      {wineCards}
      <Grid container justify="center" style={{ padding: 10 }}>
        <a href="../">Home</a>
      </Grid>
    </Grid>
  )
}

export const query = graphql`
  query WineQuery {
    allWinesCsvSheet1 {
      edges {
        node {
          date
          title
          variety
          country
          region
          instagramUrl
          url
        }
      }
    }
  }
`

export default Wine
